// firearmData.js

const firearmData = {
  "Lever Action": {
    title: "Lever Action",
    id: 0,
    description:
      "A classic firearm mechanism using a lever to load cartridges, known for its reliability and speed.",
    imgUrl: "img/portfolio/zr101.png",
    caliber: "12 Ga",
    stock: "Walnut, Plastic",
    weight: "3.1 kg",
    capacity: "4+1, 7+1",
    receiver: "Aluminium",
    barrel: "47-61-71-76 cm",
    choke: "Multi",
    system: "Lever Action",
    items: {
      ZR101: "img/portfolio/zr101.png",
      ZR102: "img/portfolio/zr102.png",
      ZR103: "img/portfolio/zr103.png",
      ZR104: "img/portfolio/zr104.png",
      ZR105: "img/portfolio/zr105.png",
      ZR106: "img/portfolio/zr106.png",
    },
  },
  Bullpup: {
    title: "Bullpup",
    id: 1,
    description:
      "A compact firearm design where the action and magazine are located behind the trigger, enhancing maneuverability.",
    imgUrl: "img/portfolio/zr151.png",
    caliber: "12 Ga",
    stock: "Synthetic, Plastic",
    weight: "3.7 kg",
    capacity: "2+1, 4+1, 9+1",
    receiver: "Aluminium",
    barrel: "47-61-66-71-76 cm",
    choke: "Multi",
    system: "Gas-Semi Auto",
    items: {
      ZR151: "img/portfolio/zr151.png",
      ZR152: "img/portfolio/zr152.png",
      ZR153: "img/portfolio/zr153.png",
      ZR154: "img/portfolio/zr154.png",
      ZR155: "img/portfolio/zr155.png",
      ZR156: "img/portfolio/zr156.png",
    },
  },
  "Fed Magazine": {
    title: "Fed Magazine",
    id: 2,
    description:
      "Featuring high-capacity magazines for extended shooting sessions without frequent reloads.",
    imgUrl: "img/portfolio/zr201.png",
    caliber: "12-20 Ga",
    stock: "Synthetic, Plastic",
    weight: "3.7 kg",
    capacity: "2+1, 4+1, 9+1",
    receiver: "Aluminium",
    barrel: "47-61-71-76 cm",
    choke: "Multi",
    system: "Gas-Semi Auto",
    items: {
      ZR201: "img/portfolio/zr201.png",
      ZR202: "img/portfolio/zr202.png",
      ZR203: "img/portfolio/zr203.png",
      ZR204: "img/portfolio/zr204.png",
      ZR205: "img/portfolio/zr205.png",
      ZR206: "img/portfolio/zr206.png",
      ZR207: "img/portfolio/zr207.png",
      ZR208: "img/portfolio/zr208.png",
      ZR209: "img/portfolio/zr209.png",
      ZR210: "img/portfolio/zr210.png",
      ZR211: "img/portfolio/zr211.png",
    },
  },
  "Over & Under": {
    title: "Over & Under",
    id: 3,
    description:
      "Double-barreled firearms with barrels arranged vertically, offering versatility and balance.",
    imgUrl: "img/portfolio/zr251.png",
    caliber: "12-20-.410 Ga",
    stock: "Walnut ",
    weight: "3.2 kg",
    capacity: "Optional",
    receiver: "Aluminium or Steel",
    barrel: "33-47-61-71-76 cm",
    choke: "Multi-Fix",
    system: "Over & Under",
    items: {
      ZR251: "img/portfolio/zr251.png",
      ZR252: "img/portfolio/zr252.png",
      ZR253: "img/portfolio/zr253.png",
      ZR254: "img/portfolio/zr254.png",
      ZR255: "img/portfolio/zr255.png",
      ZR256: "img/portfolio/zr256.png",
    },
  },
  "Side by Side": {
    title: "Side by Side",
    id: 4,
    description:
      "Traditional double-barreled firearms with barrels placed horizontally, ideal for balanced shooting.",
    imgUrl: "img/portfolio/zr301.png",
    caliber: "12 Ga",
    stock: "Walnut",
    weight: "3.2 kg",
    receiver: "Steel",
    barrel: "33-47-61-71-76 cm",
    choke: "Multi-Fix",
    system: "Side by Side",
    items: {
      ZR201: "img/portfolio/zr301.png",
      ZR202: "img/portfolio/zr302.png",
    },
  },
  "Semi Automatic": {
    title: "Semi Automatic",
    id: 5,
    description:
      "Firearms that automatically reload after each shot, offering quick follow-up shots and ease of use.",
    imgUrl: "img/portfolio/zr351.png",
    caliber: "12-20 Ga",
    stock: "Walnut, Plastic",
    weight: "3.2 kg",
    capacity: "4+1",
    receiver: "Aluminium",
    barrel: "47-61-71-76 cm",
    choke: "Fix",
    system: "Gas-Semi Auto",
    items: {
      ZR351: "img/portfolio/zr351.png",
      ZR352: "img/portfolio/zr352.png",
      ZR353: "img/portfolio/zr353.png",
      ZR354: "img/portfolio/zr354.png",
      ZR355: "img/portfolio/zr355.png",
      ZR356: "img/portfolio/zr356.png",
      ZR357: "img/portfolio/zr357.png",
      ZR358: "img/portfolio/zr358.png",
      ZR359: "img/portfolio/zr359.png",
      ZR360: "img/portfolio/zr360.png",
      ZR361: "img/portfolio/zr361.png",
      ZR362: "img/portfolio/zr362.png",
      ZR363: "img/portfolio/zr363.png",
      ZR364: "img/portfolio/zr364.png",
      ZR365: "img/portfolio/zr365.png",
    },
  },
  "Kinetic Semi Auto": {
    title: "Kinetic Semi Auto",
    id: 6,
    description:
      "Advanced semi-automatic firearms utilizing kinetic energy for enhanced performance and reliability.",
    imgUrl: "img/portfolio/zr401.png",
    caliber: "12 Ga",
    stock: "Synthetic, Plastic",
    chamber: "3 inch",
    capacity: "5+1",
    receiver: "Aluminium",
    barrel: "47-51-61-71 cm",
    choke: "Cylinder/Slug",
    system: "Gas-Operated Rotation Bolt",
    items: {
      ZR401: "img/portfolio/zr401.png",
      ZR402: "img/portfolio/zr402.png",
      ZR403: "img/portfolio/zr403.png",
      ZR404: "img/portfolio/zr404.png",
      ZR405: "img/portfolio/zr405.png",
      ZR406: "img/portfolio/zr406.png",
      ZR407: "img/portfolio/zr407.png",
      ZR408: "img/portfolio/zr408.png",
      ZR409: "img/portfolio/zr409.png",
      ZR410: "img/portfolio/zr410.png",
      ZR411: "img/portfolio/zr411.png",
      ZR412: "img/portfolio/zr412.png",
    },
  },
  "Pump Action": {
    title: "Pump Action",
    id: 7,
    description:
      "Firearms that utilize a sliding forearm to chamber rounds, offering reliable cycling and quick reloads.",
    imgUrl: "img/portfolio/zr451.png",
    caliber: "12 Ga",
    stock: "Walnut, Plastic",
    weight: "3.1 kg",
    capacity: "4+1, 7+1",
    receiver: "Aluminium",
    barrel: "47-61-71-76 cm",
    choke: "Multi",
    system: "Pump Action",
    items: {
      ZR451: "img/portfolio/zr451.png",
      ZR452: "img/portfolio/zr452.png",
      ZR453: "img/portfolio/zr453.png",
      ZR454: "img/portfolio/zr454.png",
      ZR455: "img/portfolio/zr455.png",
      ZR456: "img/portfolio/zr456.png",
      ZR457: "img/portfolio/zr457.png",
      ZR458: "img/portfolio/zr458.png",
      ZR459: "img/portfolio/zr459.png",
    },
  },
  "Single Barrel": {
    title: "Single Barrel",
    id: 8,
    description:
      "Precision firearms featuring a single barrel, ideal for accuracy and consistency in shooting.",
    imgUrl: "img/portfolio/zr501.png",
    caliber: "12-20-.410 Ga",
    stock: "Synthetic, Walnut, Plastic  ",
    weight: "2.5 kg",
    capacity: "4+1, 7+1",
    receiver: "Aluminium or Steel",
    barrel: "35-47-61-71-76 cm",
    choke: "Multi",
    system: "Single Barrel",
    items: {
      ZR501: "img/portfolio/zr501.png",
      ZR502: "img/portfolio/zr502.png",
      ZR503: "img/portfolio/zr503.png",
    },
  },
};

export default firearmData;
