import React, { useState, useEffect, useRef } from "react";
import { Products } from "./products";
import Categories from "../data/test.json";
import FirearmCollection from "../data/FirearmCollection.js";

export const Gallery = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [categoryId, setCategoryId] = useState(undefined);
  const [isVisible, setIsVisible] = useState(false);
  const [productId, setProductId] = useState(undefined);

  const galleryRef = useRef(null);

  const silahlar = new FirearmCollection();

  // Open modal
  const openModal = (category) => {
    setSelectedImage(category);
    document.body.classList.add("modal-active");
  };

  // Close modal
  const closeModal = () => {
    setSelectedImage(null);
    document.body.classList.remove("modal-active");
  };

  // Close modal when clicking on background
  const handleBackgroundClick = (e) => {
    if (e.target.classList.contains("product-info-modal")) {
      closeModal();
    }
  };

  // Animasyonları tetiklemek için IntersectionObserver kullanımı
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          }
        });
      },
      { threshold: 0.1 }
    );

    if (galleryRef.current) {
      observer.observe(galleryRef.current);
    }

    return () => {
      if (galleryRef.current) {
        observer.unobserve(galleryRef.current);
      }
    };
  }, []);

  return (
    <div id="portfolio" className="text-center" ref={galleryRef}>
      {categoryId === undefined ? (
        <div className="container">
          <div
            style={{
              position: "absolute",
              bottom: 0,
              right: 0,
              marginBottom: 50,
              marginTop: "-5vh",
            }}
          >
            {/* Glass Effect Button */}
           
          </div>
          <div className="section-title">
            <h2>Categories</h2>
            <p>
              Explore our products below. Click on any image to view more
              details.
            </p>
          </div>
          <div className="row">
            {silahlar.getAllFirearms().map((category, index) => {
              return (
                <div
                  key={index}
                  className={`col-sm-6 col-md-3 col-lg-3 portfolio-item ${
                    isVisible ? "fadeIn animated" : ""
                  }`}
                  onClick={() => openModal(category)}
                >
                  <div className="image-container">
                    <img
                      src={category.imgUrl}
                      alt={category.title}
                      className="portfolio-img"
                    />
                    <div className="image-caption">{category.title}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        Products(
          () => {
            setCategoryId(undefined);
          },
          silahlar.findById(categoryId),
          productId,
          setProductId
        )
      )}

      {selectedImage && (
        <div
          className="product-info-modal active"
          onClick={handleBackgroundClick}
        >
          <div
            className="modal-content glass"
            onClick={(e) => e.stopPropagation()}
          >
            <div
              className="modal-body"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="modal-image" style={{ textAlign: "center" }}>
                <img
                  src={selectedImage.imgUrl}
                  alt={selectedImage.title}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "80vh",
                    margin: "0 auto",
                  }}
                />
              </div>
              <div className="modal-description">
                <h3>{selectedImage.title}</h3>
                <p>{selectedImage.description}</p>
                <a href="#portfolio">
                  <button
                    onClick={() => {
                      closeModal();
                      setCategoryId(selectedImage.id);
                    }}
                    className="btn-custom"
                  >
                    Learn More
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
