import React from "react";
import Carousel from "./carousel";
import { FaPhone } from "react-icons/fa"; // FaPhone simgesini ekliyoruz

const onButtonClick = () => {
  const pdfUrl = "/img/ozzumrut_arms_catalog.pdf";
  const link = document.createElement("a");
  link.href = pdfUrl;
  link.download = "ozzumrut_arms_catalog.pdf"; // specify the filename
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const Products = (
  resetCategoryId,
  selectedCategory,
  productId,
  setProductId
) => {
  console.log("selectedCategory");
  console.log(selectedCategory);
  return (
    <div className="container">
      <div className="section-title">
        <div className="section-title">
          <h2>{selectedCategory.title}</h2>
        </div>
        <Carousel
          slides={Object.values(selectedCategory.items)}
          interval={10000}
        />
        <div
          className="card"
          style={{
            position: "relative",
            width: "100%",
            marginBottom: 30,
          }}
        >
          <img
            src="img/portfolio/IMG_6326.png"
            className="img-responsive"
            alt=""
          />
          <div className="card-body">
            <h5 className="card-title">{selectedCategory.title}</h5>
            <p className="card-text">{selectedCategory.description}</p>
          </div>
          <ul
            style={{ color: "black" }}
            className="list-group list-group-flush"
          >
            <li className="list-group-item">
              Caliber: {selectedCategory.caliber}
            </li>
            <li className="list-group-item">Stock: {selectedCategory.stock}</li>
            <li className="list-group-item">
              Weight: {selectedCategory.weight}
            </li>
            <li className="list-group-item">
              Capacity: {selectedCategory.capacity}
            </li>
            <li className="list-group-item">
              Receiver: {selectedCategory.receiver}
            </li>
            <li className="list-group-item">
              Barrel: {selectedCategory.barrel}
            </li>
            <li className="list-group-item">Choke: {selectedCategory.choke}</li>
            <li className="list-group-item">
              System: {selectedCategory.system}
            </li>
          </ul>
          <div className="card-body">
            <a onClick={onButtonClick} className="card-link fa fa-download ">
              Download Catalog
            </a>
          </div>
          <br />
          <button
            onClick={resetCategoryId}
            style={{ backgroundColor: "white" }}
          >
            Go Back
          </button>
        </div>
      </div>
    </div>
  );
};
