import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { About } from "./components/about";
import { Gallery } from "./components/gallery";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import { Fab, Action } from "react-tiny-fab";
import "react-tiny-fab/dist/styles.css";
import "./App.css";
import { FaPhone } from "react-icons/fa"; // FaPhone simgesini ekliyoruz

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Navigation />
      <Header data={landingPageData.Header} />
      <Gallery data={landingPageData.Gallery} />
      <About data={landingPageData.About} />
      <Contact data={landingPageData.Contact} />
      
      {/* Soru işareti yerine telefon ikonu */}
      <Fab icon={<FaPhone />} alwaysShowTitle={true}>
        <Action
          style={{ backgroundColor: "white" }}
          text="Email"
          onClick={() => {
            window.location.href = "mailto:sales@ozzumrutarms.com";
          }}
        >
          <i
            className="fa fa-envelope"
            style={{ fontSize: "30px", color: "aqua" }}
          ></i>
        </Action>
        <Action
          style={{ backgroundColor: "white" }}
          text="Facebook"
          onClick={() => {
            const url =
              "https://www.facebook.com/people/%C3%96z-Z%C3%BCmr%C3%BCt-Arms/61565396700884/?mibextid=LQQJ4d&rdid=G8kHzsCk4wfsTxZ0&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2F5TArDAGgVGE44xVt%2F%3Fmibextid%3DLQQJ4d";
            window.open(url, "_blank").focus();
          }}
        >
          <i
            className="fa fa-facebook"
            style={{ fontSize: "30px", color: "#1877F2" }}
          ></i>
        </Action>
        <Action
          style={{ backgroundColor: "white" }}
          text="Whatsapp"
          onClick={() => {
            const urlencode = (text) => {
              return encodeURIComponent(text)
                .replace(/[!'()*]/g, (c) => `%${c.charCodeAt(0).toString(16)}`)
                .replace(/["]/g, "%22");
            };

            const text = urlencode(document.getElementById("message").value);

            const url = `https://wa.me/+905343541346?text=${text}`;

            window.open(url, "_blank").focus();
          }}
        >
          <i
            className="fa fa-whatsapp"
            style={{ fontSize: "30px", color: "#25D366" }}
          ></i>
        </Action>
      </Fab>
    </div>
  );
};

export default App;
