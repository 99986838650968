import React, { useEffect, useRef, useState } from "react";

export const About = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  const aboutRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          }
        });
      },
      { threshold: 0.1 } // %10'u görünür olunca tetiklenecek
    );

    if (aboutRef.current) {
      observer.observe(aboutRef.current);
    }

    return () => {
      if (aboutRef.current) {
        observer.unobserve(aboutRef.current);
      }
    };
  }, []);

  const onButtonClick = () => {
    const pdfUrl = "/img/ozzumrut_arms_catalog.pdf";
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = "ozzumrut_arms_catalog.pdf"; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div id="about" ref={aboutRef}>
      <div className="container">
        <div className="row">
          {/* Görsel */}
          <div
            className={`col-xs-12 col-md-6 ${
              isVisible ? "fadeInLeft animated" : ""
            }`}
          >
            <img
              src="img/portfolio/logonobg.png"
              className="img-responsive"
              alt="Öz Zümrüt Logo"
            />
          </div>

          {/* Metin */}
          <div
            className={`col-xs-12 col-md-6 ${
              isVisible ? "fadeInRight animated" : ""
            }`}
          >
            <div className="about-text">
              <h2>About Us</h2>
              <p>
                Öz Zümrüt Arms is a premier shotgun manufacturer based in Konya,
                Turkey. We specialize in producing high-quality firearms
                featuring 4140-grade steel barrels, 7075 T6-grade aluminum
                receivers, premium walnut wood, and top-grade plastic
                components.
              </p>{" "}
              <p>
                Driven by a passion to prove ourselves on the global stage, we
                continue our journey with dedication and enthusiasm. Our
                commitment to customer satisfaction and adherence to the highest
                quality standards set us apart as a leader in the industry.
              </p>
              <p>
                Click <a onClick={onButtonClick}>here </a>
                to explore our firearms and spare parts catalog.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
