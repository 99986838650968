import React from "react";

export const Navigation = (props) => {
  return (
    <nav
      id="menu"
      className="navbar navbar-default navbar-fixed-top custom-navbar"
    >
      <div className="container">
        <div className="navbar-header d-flex align-items-center justify-content-between">
          <a
            className="navbar-brand page-scroll d-flex align-items-center"
            href="#page-top"
          >
            <img
              src="../img/portfolio/logonobg.png"
              alt="Logo"
              style={{ height: "50px", width: "auto", marginRight: "10px" }}
            />
          </a>
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1" // ID düzeltildi
            aria-expanded="false"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1" // ID düzeltildi
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="#portfolio" className="page-scroll">
                Categories
              </a>
            </li>

            <li>
              <a href="#about" className="page-scroll">
                About
              </a>
            </li>

            <li>
              <a href="#contact" className="page-scroll">
                Contact
              </a>
            </li>
            <li
              style={{
                flexDirection: "row",
                justifyContent: "space-evenly",
              }}
              className="mobile-whatsapp"
            >
              <a
                href="https://wa.me/+905343541346" // WhatsApp numaranız
                target="_blank"
                rel="noopener noreferrer"
                className="whatsapp-icon"
                style={{ flex: 1 }}
              >
                <i
                  className="fa fa-whatsapp"
                  style={{ fontSize: "30px", color: "#25D366" }}
                ></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
