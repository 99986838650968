// Firearm.js

class Firearm {
  constructor({
    title,
    id,
    description, // New property
    imgUrl,
    caliber,
    stock,
    weight,
    capacity,
    receiver,
    barrel,
    choke,
    system,
    items,
    chamber, // Optional property
  }) {
    this.title = title;
    this.id = id;
    this.description = description; // Assigning the description
    this.imgUrl = imgUrl;
    this.caliber = caliber;
    this.stock = stock;
    this.weight = weight;
    this.capacity = capacity;
    this.receiver = receiver;
    this.barrel = barrel;
    this.choke = choke;
    this.system = system;
    this.items = items;
    if (chamber !== undefined) {
      this.chamber = chamber;
    }
  }
}

export default Firearm;
