// src/Carousel.js
import React, { useState, useEffect } from "react";
import { useSwipeable } from "react-swipeable";
import "./Carousel.css";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa"; // React Icons ekliyoruz

const Carousel = ({ slides, interval = 3000 }) => {
  const [current, setCurrent] = useState(0);
  const length = slides.length;

  // Otomatik slayt geçişi
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrent((prev) => (prev === length - 1 ? 0 : prev + 1));
    }, interval);
    return () => clearInterval(timer);
  }, [current, length, interval]);

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  // Swipe handler
  const handlers = useSwipeable({
    onSwipedLeft: () => nextSlide(),
    onSwipedRight: () => prevSlide(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  if (!Array.isArray(slides) || slides.length === 0) {
    return null;
  }

  return (
    <div className="carousel" {...handlers}>
      <button className="left-arrow" onClick={prevSlide}>
        <FaChevronLeft />
      </button>
      <button className="right-arrow" onClick={nextSlide}>
        <FaChevronRight />
      </button>
      {slides.map((slide, index) => (
        <div
          className={index === current ? "slide active" : "slide"}
          key={index}
        >
          {index === current && (
            <img src={slide} alt={slide} className="image" />
          )}
        </div>
      ))}
      <div className="dots">
        {slides.map((_, index) => (
          <span
            key={index}
            className={index === current ? "dot active" : "dot"}
            onClick={() => setCurrent(index)}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
