// FirearmCollection.js

import Firearm from "./Firearm";
import firearmData from "./firearmData"; // Import the updated data

class FirearmCollection {
  constructor() {
    // Initialize the firearms array by converting the JSON object to an array of Firearm instances
    this.firearms = Object.values(firearmData).map(
      (firearmData) => new Firearm(firearmData)
    );
  }

  // Method to retrieve all firearms
  getAllFirearms() {
    return this.firearms;
  }

  // Optional: Method to find a firearm by ID
  findById(id) {
    return this.firearms.find((firearm) => firearm.id === id);
  }

  // Optional: Method to find firearms by system type
  findBySystem(systemType) {
    return this.firearms.filter((firearm) => firearm.system === systemType);
  }
}

export default FirearmCollection;
