import React from "react";
import video1 from "../assets/video/video1.mp4";

export const Header = () => {
  return (
    <video
      style={{
        width: "100vw",
        position: "relative",
        bottom: 0,
        right: 0,
        backgroundSize: "cover",
        marginTop: "70px",
        borderRadius: 15,
      }}
      loop={true}
      autoPlay={true}
      controls={false}
      muted={true}
    >
      <source src={video1} type="video/mp4" />
    </video>
  );
};
