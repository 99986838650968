import React, { useState, useRef, useEffect } from "react";
import emailjs from "emailjs-com";

const initialState = {
  name: "",
  email: "",
  message: "",
};

export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);
  const [isVisible, setIsVisible] = useState(false);
  const contactRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          }
        });
      },
      { threshold: 0.1 }
    );

    if (contactRef.current) {
      observer.observe(contactRef.current);
    }

    return () => {
      if (contactRef.current) {
        observer.unobserve(contactRef.current);
      }
    };
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearState = () => setState({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(name, email, message);

    emailjs
      .sendForm(
        "YOUR_SERVICE_ID",
        "YOUR_TEMPLATE_ID",
        e.target,
        "YOUR_PUBLIC_KEY"
      )
      .then(
        (result) => {
          console.log(result.text);
          clearState();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div
      style={{
        backgroundColor: "#121212",
        color: "#ffffff",
        padding: "50px 0",
      }}
    >
      <div
        id="contact"
        ref={contactRef}
        className="contact-section glass-effect"
      >
        <div className="container">
          <div className="col-md-8">
            <div className={`row ${isVisible ? "fadeInLeft animated" : ""}`}>
              <div className="section-title">
                <h2>Get In Touch</h2>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <form
                name="sentMessage"
                validate
                onSubmit={handleSubmit}
                className="glass-form"
              >
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Message"
                    required
                    onChange={handleChange}
                    style={{ color: "#ffffff" }}
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className="btn btn-custom btn-lg glass-button"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    const urlencode = (text) => {
                      return encodeURIComponent(text)
                        .replace(
                          /[!'()*]/g,
                          (c) => `%${c.charCodeAt(0).toString(16)}`
                        )
                        .replace(/["]/g, "%22");
                    };

                    const text = urlencode(
                      document.getElementById("message").value
                    );

                    const url = `https://wa.me/+905343541346?text=${text}`;

                    window.open(url, "_blank").focus();
                  }}
                >
                  Send Whatsapp Message
                </button>
              </form>
            </div>
          </div>
          <div
            className={`col-md-3 col-md-offset-1 contact-info ${
              isVisible ? "fadeInRight animated" : ""
            }`}
          >
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                {props.data ? props.data.phone : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul className="social-icons">
                  <li style={{ margin: "0 5px" }}>
                    <a
                      href={
                        "https://www.facebook.com/people/%C3%96z-Z%C3%BCmr%C3%BCt-Arms/61565396700884/?mibextid=LQQJ4d&rdid=4XUlsSsd7z7kYBKa&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2F5TArDAGgVGE44xVt%2F%3Fmibextid%3DLQQJ4d"
                      }
                      target="_blank"
                    >
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2024 Özzümrüt  San. Tic. Ltd. Şti. All Rights Reserved. Designed by{" "}
            <a
              href="https://www.instagram.com/creativity.pixel/"
              rel="nofollow"
              target="_blank"
            >
              CreativityPixel
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
